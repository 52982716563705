
.card-container{
    display: flex;
    column-gap: 8px;
    justify-content: center;
}
.image-container {
    width: 150px;
    height: 150px;
    border-radius: 8px;
    background-color: whitesmoke;
}
.card-information-container{
    width: 50%;
}
.product-name {
    margin: 0;
    font-weight: 600;
    line-height: 20px;
    font-size: 18px;
    color: #550000;
}
.ilustrative-image-info{
    position: relative;
    z-index: 10000;
    bottom: 26px;
    font-size: 8px;
    margin-left: 4px;
    color: whitesmoke;
}