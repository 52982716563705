.topbar-container {
    min-height: 50px;
    background-color: var(--dark-orange);
    color: #550000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    top: 0;
    position: sticky;
    z-index: 100;
    background-color: white;
    border-bottom: 1px solid whitesmoke;
}
.logo {
    width: 100px;
    position: absolute;
    left: 8px;
}
.faminto-text-logo {
    font-size: 20px;
    font-family: "Radio Canada", sans-serif;
}
