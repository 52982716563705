.category-button-container {
    display: flex;
    width: fit-content;
    border: 1px solid #F48CA0;
 
    justify-content: flex-end;
    cursor: pointer;
}

.category-option {
    padding: 5px 5px;
}