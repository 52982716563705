.footer-container{
    height: 100px;
    width: 100vw;
    background-color: #F48CA0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: end;
}
.developed-by{
    display: flex;
    justify-content: center;
    color: #550000;
}
.contact-icon{
    width: 30px;
    height: 30px;
}
.contact-container{
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
    padding: 16px;
    column-gap: 10px;
}