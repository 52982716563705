.price-label-container{
    max-width:fit-content;
    display: flex;
    justify-content: space-around;
    border-width: 1px;
    border-style: solid;
    border-color: whitesmoke;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    padding: 5px;
    align-items: center;
    column-gap: 4px;
    background-color: #F48CA0;
    color: white;
    font-weight: 600;
}
.circle{
    width: 8px;
    height: 8px;
    border: 1px solid whitesmoke;
    border-radius: 50%;
    background-color: white;
}