.switch-container{
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
}
.feed-container{
    padding: 5px;
    margin-bottom: 20px;
    min-height: calc(100vh - 200px);
}
.products-container{
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}
.session-container{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
.products-info{
    margin-top: 20px;
    color: #550000;
}

@media only screen and (min-width: 768px) {
    .feed-container{    
        max-width: 768px;
        align-self: center;
    }
}